// utils/hooks/useDebounce.js
import { useState, useEffect } from 'react'

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(
      () => {
        setDebouncedValue(value)
      },
      delay ? delay : 1000
    )

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return debouncedValue
}

export default useDebounce
